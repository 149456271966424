#product-container {
  padding-top: 40px;
  @include breakpoint(small down) { padding-top: 10px; }
}

.product-information {
  margin-left: 50px;
  @include breakpoint(medium down) { margin-left: 10px; }
  @include breakpoint(small down) { margin-left: 0; }

    .prices {

      .taxable-price {
        font-size: 26px;
        font-weight: bold;
      }

      .retail-price {
        margin-left: 20px;
        @include breakpoint(small down) { margin-left: 5px; }

        .price-label {
          font-size: 10px;
          position: absolute;
          white-space: nowrap;
          top: -5px;
        }

        .line-through {
          font-size: 15px;
          text-decoration: line-through;
        }
      }
    }
}

#product-slider {
  @include breakpoint(small down) { margin-bottom: 20px; }
}

#product-slider-nav {
  @include breakpoint(small down) { display: none; }

    img { width: 20%; }
}

.addtocart-form { margin-top: 25px; }

.add-to-cart-button { margin-top: 5px; }

.product-slide-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

    &.arrow-right { right: 0; }
    &.arrow-left { left: 0; }
}

.product-name {
  line-height: normal;
  @include breakpoint(small down) { font-size: 22px; }
}

.product-sku {
  margin-top: 10px;
  @include breakpoint(small down) { font-size: 14px; }
}


.sizes-table {
  border: 1px solid $black;
  display: inline-block;
  padding: 4px 12px;
  font-size: 13px;
  @include basic-transition;
  @include breakpoint(small down) { padding: 4px; }

    &:hover {
      background-color: $black;
      color: white;
    }
}

.list-tag {
  margin-top: 10px;

    li {
      display: inline-block;
      margin-bottom: 3px;
    }
}

.table-sizes {

    th {
      font-family: $secondary-font;
      text-transform: uppercase;
      text-align: left;
      font-weight: 400;
      font-size: 11px;
    }
}

.i-number-btn {
  position: absolute;
  top: 0;
  padding: 6px 12px 8px;

    &.i-number-minus { left: 0; }
    &.i-number-plus { right: 0; }
}


.product-separator {
  margin: 30px 0 20px;
  border: none;
  border-top: 1px solid $black;
}

.product-tabs {
  display: flex;
  justify-content: space-between;

    .tab {
      flex: 1;
      text-align: center;
      border: 1px solid white;
      border-bottom: 1px solid $black;
      padding: 18px 0 16px;
      text-transform: uppercase;
      font-family: $secondary-font;
      font-weight: 600;
      cursor: pointer;
      @include breakpoint(small down) {
        padding: 12px 0 10px;
        font-size: 12px;
      }

        &.active {
          border: 1px solid $black;
          border-bottom: 1px solid white;
        }
    }
}

.tab-container {
  display: none;
  margin-top: 30px;

    &.active {
      display: block;
    }
}


.tab-shipping {

    h3 { margin-top: 20px; }

    p { margin-bottom: 10px; }
}


#related-slider .product-container .grid-size-list { display: none; }