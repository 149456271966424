#profile-nav li { margin-bottom: -1px; }

.profile-content {
  padding-left: 30px;
  @include breakpoint(small down) {
    padding-left: 0;
    margin-top: 25px;
  }

    hr {
      border: none;
      border-top: 1px solid $black;
      margin-top: 25px;
    }
}


.table-orders {
  border-collapse: collapse;

    th {
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
    }
}

.order-row {
  cursor: pointer;
  @include basic-transition;

    &:hover { background-color: $light-gray; }

    td {
      padding: 5px 0;

      &:first-child { width: 15px; }
    }
}

.order-row-indicator {
  @include basic-transition;
  position: relative;
  top: -1px;

    &.fa-rotate-90 {
      transform: rotate(90deg);
    }
}

.order-controls {
  display: none;

    > td {
      background-color: $light-orange;
      padding: 12px 20px;
    }
}

.dl-horizontal {

  dt {
    width: 50%;
    float: left;
    clear: left;
  }

  dd {
    width: 50%;
    float: left;
    margin: 0;
    text-align: right;
  }
}

.order-controls-return {
  border-collapse: collapse;

    td {
      border-top: 1px solid $orange;
      padding: 8px;
      line-height: 1.43;
      vertical-align: middle;
    }
}

.btn-download {
  padding: 4px 8px;
  font-size: 13px;
  letter-spacing: 0;
  margin: 2px;
}

.new-return-request { display: none; }

.table-orders { min-width: 650px; }

.responsive-table { overflow-x: auto; }


// profile consent
.profile-privacy-row {
  border-bottom: 1px solid $black;
  padding: 16px 0 18px;

    h4 {
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 5px;
      color: $orange;
    }
}

.profile-privacy-col-inputs {
  display: flex;
  justify-content: space-between;
  @include breakpoint(small down) {
    flex-direction: column;
  }
}