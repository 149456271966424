#checkout-wrapper {
  padding-top: 30px;

  h2 {
    @include breakpoint(small down) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.checkout-step-title {
  border: 1px solid $black;
  background-color: $black;
  color: white;
  padding: 8px 14px 7px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: $secondary-font;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;
}

.checkout-step-title-next {
  border: 1px solid $black;
  padding: 8px 14px 7px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: $secondary-font;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;
  opacity: .3;
}

.checkout-step-title-done {
  border: 1px solid $black;
  padding: 8px 14px 7px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: $secondary-font;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;
}

.cart-summary-container {
  background-color: $light-orange;
  padding: 0;

    .checkout-step-title {
      background-color: $orange;
      border-color: $orange;
    }

    .cart-summary {
      padding: 0 20px 14px;
      font-size: 14px;
    }
}

#billing-form-btn {
  @include breakpoint(small down) { margin-bottom: 10px; }
}

#checkout-done { padding-top: 40px; }

.additional-fee {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  margin: -7px 0 12px;
}

.purchase-cancelled { padding-top: 40px; }