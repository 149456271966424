#designers-container {
  padding-top: 10px;

    h2 {
      border-bottom: 1px solid $black;
      margin-bottom: 7px;
    }

    a:hover {
      color: $orange;
    }
}