.cms-wrapper {
  padding-top: 10px;

    p { margin-bottom: 10px; }

    ul, ol { padding: 0 0 10px 20px; }
}


.content-404 {

    h1 { font-size: 50px; }
}


// size table
.size-table-page {

    table {
      border-collapse: collapse;
    }

    h3 {
      margin: 25px 0 5px;
      font-family: $secondary-font;
    }

    th {
      background-color: $orange;
      text-align: left;
      padding: 5px 12px;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    td {
      padding: 4px 12px;
      font-size: 14px;
    }

    tr:nth-child(odd) {
      background-color: $light-gray;
    }

    .size-responsive-table {
      overflow-x: scroll;

        table { min-width: 600px; }
    }
}


// dropshipping
.dropshipping-page {

    h1 {
      line-height: 1.2;
      text-align: center;
      font-size: 35px;
      max-width: 800px;
      margin: 30px auto;
    }

    h2 {
      margin-bottom: 5px;
      font-family: $secondary-font;
      text-transform: uppercase;
      font-size: 20px;
    }

    .infographic { margin: 50px 0; }

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      position: relative;
      padding: 10px 0 10px 35px;
      line-height: normal;

        &:before {
          content: '';
          background-image: url('/skins/kidsdistribution2018/images/dropshipping-list.svg');
          width: 25px;
          height: 25px;
          left: 0;
          top: 7px;
          position: absolute;
        }
    }

    .dropshipping-box {
      background-color: $orange;
      text-align: center;
      padding: 20px 0 15px;
      margin-top: 15px;
      @include breakpoint(medium down) { margin-top: 30px; }

        img {
          width: 100px;
          margin-bottom: 15px;
        }

        p {
          font-family: $secondary-font;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: -0.05em;
        }
    }

    .dropshipping-btn {
      display: block;
      background-color: $orange;
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-size: 20px;
      padding: 5px 0 8px;
      margin-top: 10px;
      @include basic-transition;

        &:hover { background-color: #e0830e; }

        span {
          display: block;
          font-size: 11px;
          margin-top: -3px;
          color: white;
        }
    }

    .table-price {
      background-color: $orange;
      text-align: center;
      padding: 20px 20px 20px;
      margin-top: 20px;
      @include breakpoint(medium down) { margin-top: 30px; }

        h4 {
          text-transform: uppercase;
          letter-spacing: 0.05em;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          margin-top: 20px;
        }

        span {
          display: block;
          font-size: 46px;
          font-weight: bold;
          line-height: 46px;

            span {
              font-size: 20px;
              line-height: 22px;
              font-weight: normal;
            }
        }
    }

    .dropshipping-review {
      text-align: center;
      display: flex;
      margin-top: 20px;

        .review-image {
          width: 110px;
          height: 110px;
          border-radius: 55px;
          overflow: hidden;
          @include breakpoint(small down) {
            width: 80px;
            height: 80px;
            border-radius: 40px;
          }
        }

        p {
          text-align: left;
          margin-left: 25px;
          @include breakpoint(small down) {
            margin-left: 18px;
            font-size: 15px;
            line-height: 1.4;
          }
        }
    }
}