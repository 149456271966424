.sales-title {
  text-align: center;
  padding: 18px 0 20px;
  border-bottom: 1px solid $light-gray;
  margin-bottom: 15px;

    h1 {
      text-transform: uppercase;
      font-size: 46px;
      letter-spacing: -0.02em;
      margin: 0;
      @include breakpoint(small down) { font-size: 30px; }
    }

    p {
      margin: 0;
      font-size: 18px;
      @include breakpoint(small down) { font-size: 16px; }
    }
}

.sales-item {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;

    .sales-item-image {
      width: 100%;
    }

    .sales-item-countdown {
      position: absolute;
      top: 11px;
      left: 12px;
      display: flex;
      align-items: center;
      background-color: $pink;
      padding: 7px 12px 8px;
      border-radius: 2px;
      z-index: 1;
      @include breakpoint(small down) {
        top: 7px;
        left: 7px;
        padding: 6px 8px 7px;
      }
    }

    .sales-item-countdown-icon {
      width: 24px;
      opacity: .65;
      @include breakpoint(small down) {
        width: 20px;
      }
    }

    .sales-item-countdown-text {
      font-size: 16px;
      padding-left: 6px;
      opacity: .65;
      color: $black;
      @include breakpoint(small down) {
        font-size: 14px;
      }
    }

    .sales-item-countdown-date {
      font-size: 16px;
      padding-left: 4px;
      color: white;
      font-weight: bold;
      @include breakpoint(small down) {
        font-size: 14px;
      }
    }

    .sales-item-link {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-decoration: none !important;
      @include breakpoint(small down) {
        position: static;
      }

        .sales-item-link-bg {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: #29a7df;
          opacity: 0;
          transition: all .15s ease-out;
          @include breakpoint(small down) {
            background: transparent;
          }
        }

        .sales-item-link-text {
          background-color: #29a7df;
          color: white;
          font-size: 18px;
          text-transform: uppercase;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 100%;
          bottom: -51px;
          transition: all .15s ease-out;
          @include breakpoint(small down) {
            position: static;
          }
        }

        img {
          width: 20px;
          margin-left: 10px;
          opacity: .65;
        }
    }

    &:hover {
      .sales-item-link {
        .sales-item-link-text {
          @include breakpoint(large) {
            transform: translateY(-51px);
          }
        }

        .sales-item-link-bg {
          @include breakpoint(large) {
            opacity: .6;
          }
        }
      }
    }
}