.btn {
  font-family: $secondary-font;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 9px 0;
  display: inline-block;
  text-align: center;
  @include basic-transition;
  border: 1px solid $black;
  font-size: 15px;
  line-height: 1.5;
  cursor: pointer;

    &.btn-empty {
      color: $black;

        &:hover {
          background-color: $black;
          color: white;
        }
    }

    &.btn-padding { padding: 9px; }

    &.btn-full {
      color: white;
      background-color: $black;

      &:hover { background-color: $orange; }

      &:disabled { opacity: .3; }
    }
}


.arrow-link {
  @include basic-transition;

    &:hover {
      img { transform: translateX(4px); }
    }
}


// text input + label
.label-input {

    label {
      text-align: left;
      text-transform: uppercase;
      font-size: 12px;
      color: $black;
      letter-spacing: 0.05em;
    }

    input {
      border: 1px solid $black;
      box-shadow: none;
      margin-bottom: 10px;
      padding-top: 6px;
    }

    textarea { border: 1px solid $black; }

    select {
      border: 1px solid $black;
      padding-top: 6px;
    }

    .i-number { margin: 5px 0; }
}


// checkbox
.checkbox[type="checkbox"]:checked,
.checkbox[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}


.checkbox[type="checkbox"]:checked + label,
.checkbox[type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  color: $black;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 15px;
}

.checkbox[type="checkbox"]:not(:checked) + label.is-invalid-label a {
  color: $red;
}


.checkbox[type="checkbox"]:checked + label:before,
.checkbox[type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 13px;
  height: 13px;
  border: 1px solid $black;
  background: #fff;
}

.checkbox.is-invalid-input[type="checkbox"]:not(:checked) + label:before {
  border-color: $red;
}

.checkbox[type="checkbox"]:checked + label:after,
.checkbox[type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 11px;
  height: 11px;
  background: $black;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: all 0.2s ease;
}

.checkbox[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.checkbox[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.checkbox[type="checkbox"].error + label {
  color: $red;
}


// radio button
.radio {
  margin: 0.1rem 0;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      line-height: 24px;
      margin: 0;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      &:before {
        content: '';
        background: white;
        border-radius: 100%;
        border: 1px solid darken($black, 25%);
        display: inline-block;
        width: 1.7em;
        height: 1.6em;
        position: relative;
        top: 1px;
        margin-right: 8px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $black;
          box-shadow: inset 0 0 0 4px $light-gray;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $black;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $light-gray;
          border-color: darken($light-gray, 25%);
          background: darken(white, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

input.error {
  border: 1px solid $red;
}

label.error {
  color: $red;
  position: relative;
  top: -8px;
}