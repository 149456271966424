$size-list-width: 100px;

.container-catalog {
  padding-top: 40px;
  @include breakpoint(small down) { padding-top: 20px; }
}

.product-container {
  border: 1px solid white;
  padding: 20px 10px 15px;

    .product-link {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
    }

    .price {
      display: inline-block;
      margin: 0 2px;

        .line-through {
          text-decoration: line-through;
          color: $orange;

            span { color: $orange }
        }
    }

    .product-id { margin-top: 15px; }

    .product-category {
      text-transform: capitalize;
      letter-spacing: 0.02em;
      font-size: 14px;
    }

    .product-name {
      font-size: 12px;
      line-height: 12px;
      margin: 4px 0 2px;
      height: 30px;
      @include flex-center;
      @include breakpoint(small down) {
        font-size: 14px;
        line-height: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        height: auto;
      }
    }

    .grid-size-list {
      display: none;
      position: absolute;
      border: 1px solid;
      top: -1px;
      right: -($size-list-width);
      width: $size-list-width;
      background-color: white;
      z-index: 1;
      text-align: center;

        li {
          border-bottom: 1px solid $black;
          margin-bottom: -1px;
          font-size: 14px;
          padding: 2px 0;
        }
    }

    img + img {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
    }

    &:hover {
      border: 1px solid $black;

        .grid-size-list {
          @include breakpoint(large) { display: block; }
        }

        img + img { opacity: 1; }
    }
}


.column-block:nth-child(4n) .grid-size-list {
  right: auto;
  left: -($size-list-width);
}


.filter-title {
  font-size: 16px;
  display: block;
  position: relative;
  padding: 10px 0;
  cursor: pointer;

    &::after {
      background-image: url(/skins/kidsdistribution2018/images/filter-plus.svg);
      right: 0;
      position: absolute;
      top: 16px;
      background-size: 12px 12px;
      display: inline-block;
      width: 12px;
      height: 12px;
      content: "";
    }

    &.filter-open::after {
      background-image: url(/skins/kidsdistribution2018/images/filter-minus.svg);
    }
}

.panel-group {
  border-bottom: 1px solid $black;

    .panel-collapse {
      display: none;
    }

}


.catalog-banner {

    h1 {
      padding: 12px 0;
      @include breakpoint(small down) { font-size: 26px; }
    }

    .catalog-banner-img {
      height: 300px;
      margin-bottom: 5px;
      @include background-center;
      @include breakpoint(medium down) { height: 26vw; }
      @include breakpoint(small down) { height: 35vw; }
    }

    .catalog-banner-text {
      background-color: $light-gray;
      padding: 15px 25px;
      @include breakpoint(small down) { display: none; }
    }
}


.filter-bullets {

    .txt-right {
      @include breakpoint(small down) {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
      }
    }
}


// Codice vecchio
@keyframes indeterminate-bar {
  0% {
    transform: translate(-50%) scaleX(0);
  }
  50% {
    transform: translate(0%) scaleX(0.3);
  }
  100% {
    transform: translate(50%) scaleX(0);
  }
}

#layout-options, #toggle-filters-button {
  margin-bottom: 5px;
}

#catalog-container {

  .catalog-spinner {
    display: none;
    z-index: 10000;
    position: fixed;
    top: 0;
    width: 100%;
    height: 3px;
    @include breakpoint(small down) {
      top: 0;
    }

    &:after {
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation: indeterminate-bar 2s linear infinite;
      animation: indeterminate-bar 2s linear infinite;
      width:100%;
      height:2px;
      background-color: $orange;
      content: '';
      display: block;
    }
  }

  &.catalog-loading {
    .catalog-spinner {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: fixed;
      z-index: 10000;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(white, 0.5);
    }
  }
}


.remove-filter {
  font-size: 12px;
  display: block;
  margin-bottom: 2px;

    img {
      margin-right: 8px;
      width: 10px;
      position: relative;
      top: -1px;
    }
}


.breadcrumb {

    li { display: inline-block; }

    a {
      display: inline-block;
      padding: 0 4px;
    }

    li:last-child a {
      font-family: $secondary-font;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 25px;
      position: relative;
      top: 7px;
    }
}


.radio-layout {
  display: inline-block;
  margin-top: 1px;

    input {
      position: absolute;
      left: -9999px;

        &:checked+label {
          background-color: $orange;
        }
    }

    label {
      padding: 3px 7px 5px 8px;
      margin: 0;
      border: 1px solid $black;
    }
}

.filter-btn {
  border: 1px solid $black;
  width: 100px;
  padding: 6px 10px 5px;
  position: relative;
  top: 1px;
  @include flex-center-between;
  margin-left: 10px;

  span { font-size: 15px; }
}

.rotateimg180 {
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.filter-by-block {
  @include breakpoint(small down) { display: none; }
}


#sort-options {

    .sort-btn {
      border: 1px solid $black;
      width: 180px;
      padding: 6px 10px 5px;
      position: relative;
      top: 1px;
      @include flex-center-between;
      margin-left: 10px;

      span { font-size: 15px; }
    }

    .sort-dropdown {
      display: none;
    }

    .sort-dropdown {
      position: absolute;
      z-index: 20;
      background-color: white;
      top: 35px;
      left: 10px;
      right: 0;
      border: 1px solid $black;
      text-align: left;

        a {
          border-top: 1px solid $black;
          margin-top: -1px;
          padding: 5px 10px;
          text-transform: capitalize;
          display: block;

            &:hover { background-color: $light-gray; }
        }
    }

    &:hover {
      .sort-dropdown { display: block; }
    }
}


.pagination {
  margin: 20px 0;

    li { display: inline-block; }

    a {
      display: inline-block;
      width: 35px;
      height: 35px;
      border: 1px solid white;
      padding-top: 5px;
      font-family: $secondary-font;
    }

    img {
      width: 9px;
      position: relative;
      top: -1px;
    }

    a:hover,
    .active a {
      border: 1px solid $black;
    }
}


.toggle-subfilter {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.subfilter-list {
  display: none;
  padding: 4px 10px;
  background-color: $light-gray;
}

.subcategory-minus { display: none; }

.subfilter-open {

  .subcategory-plus {
    display: none;
  }

  .subfilter-list {
    display: block;
  }

  .subcategory-minus {
    display: block;
    position: relative;
    top: 8px;
  }
}