// fonts
.montserrat { font-family: $secondary-font; }

// colors
.white { color: white; }
.orange { color: $orange; }

// space
.ls-005 { letter-spacing: 0.05em; }

// Transform
.upper { text-transform: uppercase; }
.bold{ font-weight: 600;}
.capitalize { text-transform: capitalize; }
.txt-center { text-align: center; }
.txt-right { text-align: right; }
.txt-left { text-align: left; }
.underline { text-decoration: underline; }
.weight-normal { font-weight: 400; }