// color
$black: #000000;
$gray: #A3A3A3;
$light-gray: #F2F2F2;
$orange: #F99C27;
$light-orange: #fff1df;
$pink: #EF5BA1;
$red: #DE252A;
$green: #16a228;

// fonts
$primary-font: 'Lato', Arial, Helvetica, sans-serif;
$secondary-font: 'Montserrat', Arial, Helvetica, sans-serif;

// Slick slider
$slick-font-path: '/skins/kidsdistribution2018/fonts/slick/';
$slick-loader-path: '/skins/kidsdistribution2018/images/';