footer {
  margin-top: 50px;

    a {
      @include basic-transition;

      &:hover { color: orange; }
    }
}

.footer-top {
  background-color: $gray;
  padding: 8px 0;
  @include breakpoint(small down) { padding: 12px 0; }

    img { width: 19px; }

    span {
      font-size: 14px;
      margin-left: 8px;
    }

    > div > div {
      @include breakpoint(small down) {
        flex-direction: column;
        text-align: center;
      }
    }
}


.footer-center {
  background-color: $light-gray;
  @include breakpoint(small down) { text-align: center; }

    h5 {
      font-weight: normal;
      padding: 10px 0 6px;
      @include breakpoint(small down) { padding: 25px 0 0; }
    }

    p {
      font-size: 14px;
      margin-top: 7px;
    }
}


.footer-social {
  text-align: center;
  padding: 10px 0 12px;

    a {
      display: inline-block;
      margin: 0 20px;
    }
}


.footer-bottom {
  padding: 8px 0;

    span {
      font-size: 12px;
      padding-top: 7px;
      display: inline-block;
      @include breakpoint(small down) { padding-top: 10px; }
    }

    .footer-bottom-box {
      width: 300px;
      @include breakpoint(small down) { width: auto; }
    }

    .flex-container {
      @include breakpoint(small down) {
        flex-direction: column;
        text-align: center;
      }
    }

    .txt-right {
      @include breakpoint(small down) {
        text-align: center;
        margin-top: 10px;
      }

        img { width: 250px; }
    }

    .logo-zero11 {
      width: 60px;
      margin-left: 6px;
      @include basic-transition;

        &:hover { opacity: .7; }
    }

    .logo-rewix {
      width: 62px;
      margin-left: 8px;
      @include basic-transition;

        &:hover { opacity: .7; }
    }
}


#subscribe-newsletter {

    #newsletter-arrow {
      background-color: $black;
      line-height: normal;
      height: 39px;
      width: 39px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include basic-transition;

        &:hover { background-color: $orange; }

        img { width: 23px; }
    }

    button { font-size: 13px; }

    > .label-input { margin-top: -8px; }

    #newsletter-step-2 { display: none; }
}

#newsletter-success {
  display: none;

    span {
      display: block;
      text-transform: uppercase;
      background-color: #bbf7bb;
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      color: green;
      border: 1px solid green;
      padding: 3px 0;
    }
}