* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $primary-font;
  color: $black;
}

body {
  overflow-x: hidden;

    &.show-nav { overflow-y: hidden; }
}

a { text-decoration: none; }

.wrapper {
  margin-top: 41px;
  min-height: calc(100vh - 550px);
  @include breakpoint(medium down) {
    margin-top: 0;
  }
}

.relative { position: relative; }

.list-unstyled { list-style: none; }

.width-100 { width: 100%; }

.width-50 { width: 50%; }

.float-right { float: right; }

.block { display: block; }

.inline-block { display: inline-block; }

.slick-slide img.link-arrow { display: inline-block; }

.columns-right{
  padding-right: .9375rem;

  @include breakpoint(small only) {
    padding-right: .625rem;
    padding-left: .625rem;
  }
  
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}


// backgrounds
.bg-light-gray { background-color: $light-gray; }

// spacing
.margin-0 { margin: 0; }
.margin-top-10 { margin-top: 10px; }
.margin-top-20 { margin-top: 20px; }
.padding-top-20 { padding-top: 20px; }
.margin-top-40 { margin-top: 40px; }
.margin-top-50 { margin-top: 50px; }
.margin-bottom-10 { margin-bottom: 10px; }
.margin-bottom-20 { margin-bottom: 20px; }
.margin-bottom-30 { margin-bottom: 30px; }
.margin-bottom-50 { margin-bottom: 50px; }
.margin-50-vert {
  margin: 50px 0;
  @include breakpoint(small down) { margin: 30px 0; }
}
.margin-mobile-20 {
  @include breakpoint(small down) { margin-bottom: 20px; }
}

// mixins
@mixin basic-transition { transition: all .15s; }

@mixin background-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#button {
  display: inline-block;
  background-color: #FF9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 50px;
  right: 30px;
  @media screen and (max-width: 39.9375em) {
    bottom: 0px;
    right: 0px;
    border-radius: 0px;
  }
  transition: background-color .3s, 
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  img {
    transform: rotate(180deg);
    width: 30px;
    margin-top: 7px;
  }
}
#button:hover {
  cursor: pointer;
  background-color: #333;
}
#button:active {
  background-color: #555;
}
#button.show {
  opacity: 1;
  visibility: visible;
}


// chat
div#lhc_status_container {
  z-index: 1;
  @include breakpoint(small down) { display: none !important; }
}