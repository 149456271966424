// home slider
#slider-home {

    a {
      width: 100%;
      height: 400px;
      @include background-center;
      @include breakpoint(small down) { height: 180px; }
    }
}

.slider-arrows {
  position: absolute;
  left: 0;
  bottom: 6px;
}

.slider-home-arrow {
  background-color: white;
  padding: 24px 28px;
  width: 67px;
  cursor: pointer;
  @include breakpoint(small down) {
    padding: 18px 22px;
    width: 52px;
  }
}


// home box
.home-title {
  font-size: 18px;
  font-weight: normal;
}

.home-box {
  cursor: pointer;

    a {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .home-box-img-container {
      height: 350px;
      overflow: hidden;
      margin-bottom: 12px;
      @media only screen and (max-width: 1200px) { height: 29vw; }
      @include breakpoint(small down) { height: 58vw; }
    }

    .home-box-img-container-small {
      height: 350px;
      overflow: hidden;
      margin-bottom: 12px;
      @media only screen and (max-width: 1200px) { height: 29vw; }
    }

    .home-box-img {
      height: 100%;
      @include background-center;
      @include basic-transition;
    }

    h3 {
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
    }

    &:hover {
      .link-arrow { transform: translateX(5px); }
      .home-box-img { transform: scale(1.05); }
    }
}

.home-box-margin {
  @include breakpoint(small down) { margin-top: 25px; }
}

.link-arrow {
  width: 35px;
  @include basic-transition;
  position: relative;
  top: -1px;
  margin-left: 5px;
}

.sales-icon {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  width: 46px;
  height: 46px;
  @include breakpoint(small down) {
    left: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
  }
}


// home registration
.home-registration {
  height: 100%;
  background-color: $light-orange;
  padding: 25px 30px;
  @include breakpoint(medium only) { display: none; }
  @include breakpoint(small down) { padding: 20px; }

    .home-registration-title {
      line-height: 24px;
      font-size: 29px;
      @media only screen and (max-width: 1200px) {
        font-size: 22px;
      }

        span {
          display: block;
          font-size: 16px;
        }
    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
    }

    .padd-right-3 { padding-right: 3px }
    .padd-left-3 { padding-left: 3px }

    hr {
      margin: 15px 0;
      border-top: 1px solid $orange;
    }
}

.home-registration-list {
  margin-top: 15px;

    li {
      line-height: 33px;
      font-size: 18px;
      @media only screen and (max-width: 1200px) {
        font-size: 16px;
      }

      &::before {
        content: url(/skins/kidsdistribution2018/images/pink-plus.svg);
        margin-right: 12px;
      }
    }
}


// home title + link
.home-title-link {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $black;
  padding-bottom: 5px;
  margin-bottom: 20px;

    span { margin-right: 5px; }
}


// slider logos
#slider-logos {

    img {
      padding: 0 15px;
      @include breakpoint(small down) { margin: 0 auto; }
    }
}


// blog
.blog-tag {
  background-color: $black;
  color: white;
  text-transform: uppercase;
  padding: 4px 10px 3px;
  display: inline-block;
  font-size: 12px;
  margin: 0 5px 5px 0;
  letter-spacing: 0.05em;
}

.home-article-img {
  @include background-center;

    &.first-article {
      height: 400px;
      @include breakpoint(medium down) { height: 70vw; }
    }
    &.second-article {
      height: 200px;
      @include breakpoint(medium down) { height: 35vw; }
      @include breakpoint(small down) { height: 70vw; }
    }
}

.home-article-txt {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include breakpoint(small down) {
    height: auto;
    margin: 15px 0;
  }

    &.first-article {
      padding-bottom: 30px;
      @include breakpoint(medium only) { margin-top: 15px; }
      @include breakpoint(small down) { padding-bottom: 0; }

        h3 {
          font-size: 24px;
          @include breakpoint(small down) { font-size: 20px; }
        }
    }

    &.second-article {
      h3 {
        font-size: 18px;
        @include breakpoint(small down) { font-size: 20px; }
      }
    }

    h3 {
      line-height: normal;
      margin: 6px 0;
      @include breakpoint(small down) { margin: 2px 0; }
    }

    .arrow-link {
      @include breakpoint(small down) { margin-top: 5px; }
    }
}