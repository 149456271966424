// top banner
.top-banner {
  font-size: 12px;
  padding: 6px 32px;
  @include breakpoint(small down) {
    height: 48px;
    @include flex-center;
  }
}

.close-top-banner {
  @include basic-transition;
  position: absolute;
  right: 8px;
  width: 15px;
  top: 7px;
  cursor: pointer;
  @include breakpoint(small down) {
    top: 15px;
  }

    &:hover {
      opacity: .7;
    }
}


// header
header {
  padding: 20px 0 10px;
  @include breakpoint(medium down) {
    padding: 15px 0;
    border-bottom: 1px solid $black;
  }
}


.header-logo {
   max-width: 300px;
   width: 100%;
   @include breakpoint(small down) { display: none; }
}

.header-logo-mobile {
  display: none;
  width: 104px;
  padding-left: 22px;
  margin: 0 auto;
  @include breakpoint(small down) { display: block; }
}


.navigation {
  z-index: 30;
  position: absolute;
  width: 100%;
  top: 121px;
  @include breakpoint(medium down) {
    width: 270px;
    height: 100%;
    position: fixed;
    top: 0;
    left: -270px;
    background: $light-orange;
    font-size: .8em;
    font-family: sans-serif;
    font-weight: 300;
    transition: all .3s ease;
    border-bottom: none;
  }

    .navigation-menu {
      background-color: white;
      padding-top: 10px;
      border-bottom: 3px solid $black;
      @include breakpoint(medium down) {
        background-color: transparent;
        padding-top: 0;
        border-bottom: none;
      }
    }

    .sticky-cart-icon {
      position: relative;
      float: right;
      top: -5px;
      display: none;
    }

    &.top-banner-closed {
      top: 90px;
      @include breakpoint(medium down) { top: 0; }
    }

    &.sticky {
      position: fixed;
      top: 0;

        .float-right {
          float: none;
          position: relative;
          left: -4px;
        }

        .main-menu-item:last-child > a { padding-right: 1.6vw;}

        .sticky-cart-icon { display: block; }
    }
}

.navigation-banner {
  text-align: center;
  background-color: rgba(254, 254, 254, 0.9);
  padding: 6px 0 8px;

  span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: $pink;
  }
}


.subscribe-link-fixed {
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 4px;
  letter-spacing: 0.03em;
  @media only screen and (max-width: 1200px) {
    display: none;
  }

    &.fixed-btn {
      margin: 0 16px 0 12px;
      background-color: $orange;
      padding: 0 5px;
    }
}


.main-menu-item {
  display: inline-block;
  @include breakpoint(medium down) {
    display: block;
    float: none;
  }

    > a {
      font-family: $secondary-font;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.05em;
      padding: 2px 20px 4px;
      position: relative;
      display: inline-block;
      @include breakpoint(medium down) {
        display: block;
        padding: 12px 0 10px;
        border-bottom: 1px solid $orange;
      }

        &:after {
          content: '';
          position: absolute;
          background-color: $black;
          left: 0;
          right: 0;
          height: 0;
          bottom: -3px;
          transition: all .1s;
        }

        &:hover:after {
          height: 6px;
          @include breakpoint(medium down) { height: 0; }
        }
    }

    .diff-color-item {

      &.orange {
        color: $orange;
        &:after { background-color: $orange; }
      }

      &.pink {
        color: $pink;
        &:after { background-color: $pink; }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 3px;
        bottom: -3px;
        @include breakpoint(medium down) { display: none; }
      }
    }

    &:first-child > a { padding-left: 0; }
    &:last-child > a { padding-right: 0; }

    &:hover {
      .submenu-container {
        display: block;
        @include breakpoint(medium down) { display: none; }
      }
    }

}


.mobile-logout {
  background: none;
  border: none;
  font-family: $secondary-font;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  padding: 14px 0;
}


.submenu-container {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: $light-gray;
  top: 41px;
  max-width: 1170px;
  margin: 0 auto;
  @include breakpoint(medium down) {
    position: static;
    border: none;
    background: none;
  }
}

.main-menu-item.relative .submenu-container {
  top: 31px;
  width: 200px;
}


.submenu {
  display: flex;
  @include breakpoint(medium down) {
    display: block;
    padding: 10px 0 20px;
  }

    > div {
      flex: 1;
      padding: 10px 23px 14px;
      @include breakpoint(medium down) { padding: 0 15px; }

      &:not(:last-child) {
        border-right: 1px solid $black;

        @include breakpoint(medium down) { border: none; }
      }
    }

    a {
      text-transform: uppercase;
      font-size: 11px;
      display: block;
      line-height: 1.7;
      letter-spacing: 0.03em;
      @include basic-transition;

        &:hover {
          color: $orange;
        }
    }

    h4,
    h4 a {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
    }

    .menu-image {
      @include background-center;

        a {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
    }
}


.language-dropdown {

    .flag-image {
      width: 15px;
      position: relative;
      top: -2px;
      margin-right: 7px;
    }

    .sort-image {
      width: 7px;
      margin-left: 5px;
    }
}


.header-icon {
  display: inline-block;
  padding-left: 20px;
  @include basic-transition;

    span {
      display: block;
      font-size: 11px;
      white-space: nowrap;

        span { display: inline; }
    }

    .search-icon { width: 22px; }

    .profile-icon {
      width: 21px;
      padding-bottom: 1px;
    }

    .cart-icon {
      width: 20px;
    }

    &:hover {
      opacity: .7;
    }
}


.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 3px 8px;
  border: 1px solid white;

    ul {
      display: none;
      position: absolute;
      z-index: 50;
      background-color: white;
      border: 1px solid $black;
      left: -1px;
      top: 30px;
      padding: 6px 12px 7px;

        a:hover {
          color: $orange;
        }
    }

    &:hover {
      border: 1px solid $black;

        ul {
          display: block;
        }
    }
}


.profile-dropdown {
  padding: 3px 3px 0;
  margin: 0 -3px 0 20px;

    img { width: 21px; }

    span {
      display: block;
      font-size: 11px;
    }

    ul {
      top: 46px;
      width: 90px;
    }

    input {
      border: none;
      cursor: pointer;

        &:hover {
          color: $orange;
        }
    }
}


// search modal
#search-modal {
  display: none;
  position: absolute;
  z-index: 1000;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

    &.search-open {
      @include flex-center;
    }
}


#mainsearch {
  background: none;
  border: 1px solid $black;
  box-shadow: none;
  font-size: 16px;
  height: auto;
  margin: 0;
  width: 100%;
  padding-left: 12px;
}

.search-form { width: 100%; }

.close-search-modal {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 16px;
  cursor: pointer;
  @include basic-transition;

    &:hover {
      opacity: .7;
    }
}

#search-trigger { cursor: pointer; }


// mobile menu
#nav-toggle {
  cursor: pointer;
  padding: 10px 35px 16px 0px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 28px;
  background: #343434;
  position: absolute;
  display: block;
  content: '';
}

#nav-toggle span:before {
  top: -10px;
}

#nav-toggle span:after {
  bottom: -10px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  transition: all 200ms ease-in-out;
}

#nav-toggle.active span {
  background-color: transparent;
}

#nav-toggle.active span:before,
#nav-toggle.active span:after {
  top: 0;
}

#nav-toggle.active span:before {
  transform: rotate(45deg);
}

#nav-toggle.active span:after {
  transform: rotate(-45deg);
}

.position {
  position: absolute;
  top: 62px;
  left: 15px;
  transition: all .3s ease;
  z-index: 2;
  @include breakpoint(small down) {
    top: 74px;
  }

    &.top-banner-closed {
      top: 33px;
      @include breakpoint(small down) {
        top: 25px;
      }
    }
}

.main {
  width: 100%;
  position: absolute;
  left: 0;
  transition: .3s ease all;
}

.show-nav .navigation,
.show-nav .position,
.show-nav .main {
  transform: translateX(270px);
}

.show-nav .navigation { overflow-y: auto; }

.search-mobile {

    #mainsearch {
      margin-bottom: 0;
      text-align: left;
      font-size: 16px;
      background-color: white;
      border: 1px solid $orange;
      border-radius: 4px;
    }
}

.mobile-languages {
  margin: 18px 0 60px;

    ul {
      display: flex;
      justify-content: space-between;
    }

    img {
      width: 30px;
      opacity: .5;
    }

    .mobile-active {
      border-bottom: 3px solid $orange;
      padding-bottom: 6px;

        img {
          opacity: 1;
        }
    }
}